/** @jsx jsx */
import { Dialog } from '@datacamp/waffles/dialog';
import { jsx } from '@emotion/react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import React, { useCallback } from 'react';

import CourseOutlineOutsideButtons from '../../containers/CourseOutlineOutsideButtons';

import type { ChapterOutline } from './Chapter/Chapter';
import Chapter from './Chapter/Chapter';
import type { ExerciseOutline } from './Chapter/ChapterExercises';

const listStyle = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
};

type Props = {
  course: {
    author_field?: string;
    chapters: ChapterOutline[];
    image_url: string;
    progress?: {
      user_chapters: {
        [id: number]: unknown;
      };
    };
    reduced_outline?: boolean;
    title: string;
    university?: string;
  };
  courseRef: number | string;
  currentChapter: ChapterOutline;
  currentExerciseId?: number;
  currentExercises: ExerciseOutline[];
  currentExercisesProgress?: unknown[];
  headless?: boolean;
  learningMode: string | null;
  onExit: () => void;
  onViewDetailExercises: (
    isExpanding: any,
    isCurrentChapter: any,
    settings: any,
  ) => void;
};

const CourseOutline: React.FC<Props> = ({
  course,
  courseRef,
  currentChapter,
  currentExerciseId,
  currentExercises,
  currentExercisesProgress,
  headless,
  learningMode,
  onExit,
  onViewDetailExercises,
}) => {
  const { reduced_outline: reducedOutline } = course;
  const isSingleChapter = learningMode === 'singleChapter';

  const onFirstLinkRendered = useCallback((el: HTMLElement | null) => {
    if (el) {
      // this guides the focus to the first link in the first opened chapter
      // for screen readers. Otherwise they have to tab through the whole
      // document
      el.focus();
      // blur to not show the first link as focussed for regular users
      el.blur();
    }
  }, []);

  const chapters = map(
    filter(course.chapters, (ch) => {
      if (isSingleChapter) {
        return ch.id === currentChapter.id;
      }

      return !reducedOutline || ch.id === currentChapter.id;
    }),
    (chapter, index) => (
      <Chapter
        key={index}
        onFirstLinkRendered={index === 0 ? onFirstLinkRendered : undefined}
        {...{
          ...chapter,
          userChapterProgress:
            course.progress &&
            course.progress.user_chapters &&
            course.progress.user_chapters[index],
          onViewDetailExercises,
          courseRef,
          currentChapter,
          currentExercises,
          currentExerciseId,
          currentExercisesProgress,
          disableTabbing: headless,
          onExit,
          hideChapterNumber: reducedOutline,
        }}
      />
    ),
  );

  if (headless) {
    return <ul data-cy="outline-container">{chapters}</ul>;
  }

  return (
    <>
      <Dialog.Header>{course.title}</Dialog.Header>
      <Dialog.Body id="course-outline-chapters">
        <ul css={listStyle} data-cy="outline-container">
          {chapters}
        </ul>
      </Dialog.Body>
      <Dialog.Footer>
        {!isSingleChapter ? <CourseOutlineOutsideButtons /> : <></>}
      </Dialog.Footer>
    </>
  );
};

export default CourseOutline;
