/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Checkmark, Cross } from '@datacamp/waffles/icon';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { css, jsx } from '@emotion/react';
import startCase from 'lodash/startCase';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { SaveScoreBody } from '../../helpers/ai/api';
import { saveScore } from '../../helpers/ai/api';

import { FeedbackDialog } from './RatingDialog';

type Props = {
  inverted?: boolean;
  predictionId: number;
};

const Rating: FC<Props> = ({ inverted, predictionId }) => {
  const [scored, setScored] = useState(false);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const { t } = useTranslation(['translation', 'common']);

  const submitFeedback = useCallback(
    (body: SaveScoreBody) => {
      saveScore({ body, params: { predictionId } });
      setScored(true);
    },
    [predictionId],
  );

  const handlePositiveFeedback = useCallback(() => {
    submitFeedback({ score: 1 });
  }, [submitFeedback]);

  const handleNegativeFeedback = useCallback(() => {
    setFeedbackDialogOpen(true);
  }, []);

  const handleDialogFeedback = useCallback(
    (submittedValue?: string) => {
      submitFeedback({ score: 0, feedback: submittedValue });
      setFeedbackDialogOpen(false);
    },
    [submitFeedback],
  );

  const onClose = useCallback(() => {
    submitFeedback({ score: 0 });
    setFeedbackDialogOpen(false);
  }, [submitFeedback]);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        max-height: 22px;
      `}
    >
      {scored ? (
        <Paragraph
          css={css({
            marginBottom: 0,
          })}
          inverted={inverted}
        >
          {t('CodeExplanationRating.scoredText')}
        </Paragraph>
      ) : (
        <>
          <FeedbackDialog
            isOpen={feedbackDialogOpen}
            onClose={onClose}
            submitFeedback={handleDialogFeedback}
          />
          <Paragraph
            css={css({
              marginBottom: 0,
              marginRight: tokens.spacing.medium,
            })}
            inverted={inverted}
          >
            {t('CodeExplanationRating.text')}
          </Paragraph>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <Button
              css={css`
                color: ${inverted ? tokens.colors.white : tokens.colors.navy};
              `}
              inverted={inverted}
              data-cy="code-explanation-rating-yes-button"
              aria-label={startCase(t('yes', { ns: 'common' }))}
              variant="plain"
              onClick={handlePositiveFeedback}
              iconLeft={
                <Checkmark
                  color={inverted ? tokens.colors.white : tokens.colors.navy}
                />
              }
            >
              {startCase(t('yes', { ns: 'common' }))}
            </Button>
            <Button
              css={css`
                color: ${inverted ? tokens.colors.white : tokens.colors.navy};
              `}
              inverted={inverted}
              data-cy="code-explanation-rating-no-button"
              aria-label={startCase(t('no', { ns: 'common' }))}
              variant="plain"
              onClick={handleNegativeFeedback}
              iconLeft={<Cross color={tokens.colors.red} />}
            >
              {startCase(t('no', { ns: 'common' }))}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Rating;
