/** @jsx jsx */
import { Checkbox } from '@datacamp/waffles/checkbox';
import { Dialog } from '@datacamp/waffles/dialog';
import { TextArea } from '@datacamp/waffles/text-area';
import { tokens } from '@datacamp/waffles/tokens';
import { css, jsx } from '@emotion/react';
import startCase from 'lodash/startCase';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type FeedbackDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  submitFeedback: (value?: string) => void;
};

export const FeedbackDialog: FC<FeedbackDialogProps> = ({
  isOpen,
  onClose,
  submitFeedback,
}) => {
  const { t } = useTranslation(['translation', 'common']);

  const checkboxes = [
    t('CodeExplanationRatingDialog.incorrect'),
    t('CodeExplanationRatingDialog.slow'),
    t('CodeExplanationRatingDialog.hardToUnderstand'),
    t('CodeExplanationRatingDialog.unrelated'),
    t('CodeExplanationRatingDialog.improperDisplay'),
    startCase(t('other', { ns: 'common' })),
  ];

  const [checked, setChecked] = useState<boolean[]>(
    new Array(checkboxes.length).fill(false),
  );
  const toggleCheckbox = useCallback(
    (index: number) => {
      const newChecked = [...checked];
      newChecked[index] = !newChecked[index];
      setChecked(newChecked);
    },
    [checked],
  );
  const [otherValue, setOtherValue] = useState<string>('');
  const isOtherChecked = useMemo(() => checked[checked.length - 1], [checked]);

  const constructFeedbackMessage = useCallback((): string => {
    const values = checkboxes.filter((_, index) => checked[index]);
    if (values.length <= 0) {
      return '';
    }

    const checkboxString = `${t(
      'CodeExplanationRatingDialog.checkboxes',
    )}: - ${values.join('; - ')}`;
    if (otherValue && checked[checked.length - 1]) {
      return `${checkboxString};\n${t(
        'CodeExplanationRatingDialog.additionalFeedback',
      )}: ${otherValue}`;
    }

    return `${checkboxString};`;
  }, [checkboxes, checked, otherValue, t]);

  const onClick = useCallback(() => {
    submitFeedback(constructFeedbackMessage());
  }, [constructFeedbackMessage, submitFeedback]);

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Dialog.Header>{t('CodeExplanationRatingDialog.header')}</Dialog.Header>
      <form id="prediction-feedback-form" name="predictionFeedbackForm">
        <Dialog.Body>
          <div
            css={css({
              display: 'flex',
              flexDirection: 'column',
              gap: tokens.spacing.small,
            })}
          >
            {checkboxes.map((checkbox, index) => (
              <Checkbox
                key={index}
                checked={checked[index]}
                onChange={() => toggleCheckbox(index)}
              >
                {checkbox}
              </Checkbox>
            ))}
            {isOtherChecked && (
              <TextArea
                aria-label={t(
                  'CodeExplanationRatingDialog.otherCheckedTextareaLabel',
                )}
                onChange={(event) => setOtherValue(event.target.value)}
                value={otherValue}
              />
            )}
          </div>
        </Dialog.Body>
        <Dialog.Footer
          css={css({
            boxShadow: 'rgb(239, 239, 245) 0px -1px 0px 0px',
            justifyContent: 'flex-end',
          })}
        >
          <Dialog.Button disabled={false} onClick={onClick} variant="primary">
            {t('CodeExplanationRatingDialog.button')}
          </Dialog.Button>
        </Dialog.Footer>
      </form>
    </Dialog>
  );
};
