/** @jsx jsx */
import { mediaQuery } from '@datacamp/waffles/helpers';
import { useMediaQuery } from '@datacamp/waffles/hooks';
import { Checkmark } from '@datacamp/waffles/icon';
import { darkThemeStyle } from '@datacamp/waffles/theme';
import { tokens } from '@datacamp/waffles/tokens';
import { css, jsx } from '@emotion/react';
import { useLottie } from 'lottie-react';
import type { ReactNode } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SnowflakeIcon from '../../assets/images/Snowflake.svg';
import type { StreakWeekItem } from '../../redux/reducers/streaks/types';

import shimmer from './animations/shimmer.json';

const ANIMATION_TIME = 800; // transition time (ms)
const INITIAL_ANIMATION_DELAY = 800; // initial delay before the flip starts (ms)

const useVariableSizes = ({ days }: { days: number }) => {
  const { isAboveSmall } = useMediaQuery();

  if (isAboveSmall && days < 1000) {
    return {
      flash: '106px',
      font: '140px',
    };
  }

  if (isAboveSmall) {
    return {
      flash: '92px',
      font: '100px',
    };
  }

  if (days < 1000) {
    return {
      flash: '92px',
      font: '120px',
    };
  }

  return {
    flash: '78px',
    font: '100px',
  };
};

const FlashShimmer: React.FC<React.HTMLProps<HTMLDivElement>> = (props) => {
  const { View, setSpeed } = useLottie({
    ...props,
    animationData: shimmer,
    autoplay: true,
    initialSegment: [0, 300],
    loop: true,
  });

  useEffect(() => {
    setSpeed(2);
  }, [setSpeed]);
  return View;
};

const mapStatusToColor = (status: 'frozen' | 'met' | 'unmet'): string => {
  switch (status) {
    case 'met':
      return tokens.colors.yellow;
    case 'frozen':
      return tokens.colors.blueDark;
    case 'unmet':
    default:
      return tokens.colors.navyLight;
  }
};

const mapStatusToIcon = (status: 'frozen' | 'met' | 'unmet'): ReactNode => {
  switch (status) {
    case 'met':
      return <Checkmark />;
    case 'frozen':
      return <img src={SnowflakeIcon} alt="" />;
    case 'unmet':
    default:
      return <></>;
  }
};

const StreakDay: React.FC<{
  day: string;
  status: 'frozen' | 'met' | 'unmet';
  today?: boolean;
}> = ({ day, status, today = false }) => (
  <div
    css={{
      alignItems: 'center',
      display: 'flex',
      fontSize: tokens.fontSizes.large,
      fontWeight: tokens.fontWeights.bold,
      lineHeight: tokens.lineHeights.tight,
      flexDirection: 'column',
      flexWrap: 'nowrap',
      gap: tokens.spacing.xsmall,
    }}
  >
    <div
      css={{
        color: today
          ? tokens.colors.greyLight
          : tokens.colors.navySubtleTextOnLight,
        marginBottom: tokens.spacing.xsmall,
      }}
    >
      {day[0]}
    </div>
    <div
      css={{
        alignItems: 'center',
        background: mapStatusToColor(status),
        borderRadius: tokens.borderRadius.circle,
        display: 'flex',
        height: tokens.sizing.small,
        justifyContent: 'center',
        margin: `0 2px`,
        width: tokens.sizing.small,
        ...(today
          ? {
              outline: `${tokens.borderWidth.medium} solid ${tokens.colors.navySubtleTextOnLight}`,
            }
          : null),
      }}
    >
      {mapStatusToIcon(status)}
    </div>
  </div>
);

type StreakWidgetProps = {
  streakLengthInDays: number;
  streakWeekView: StreakWeekItem[];
};

export const StreakWidget: React.FC<StreakWidgetProps> = ({
  streakLengthInDays,
  streakWeekView,
}) => {
  const { t } = useTranslation();

  const [isStreakAnimationFinished, setIsStreakAnimationFinished] = useState(
    false,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsStreakAnimationFinished(true);
    }, INITIAL_ANIMATION_DELAY);

    return () => clearTimeout(timer);
  }, [streakLengthInDays]);

  // Sunday is 0, we want it to be 6
  const todayIndex = (new Date().getDay() - 1 + 7) % 7;

  const days = streakWeekView.map((day) => {
    return t(`StreakWidget.weekdayLabel${day.weekday}`);
  });

  const sizes = useVariableSizes({ days: streakLengthInDays });

  return (
    <div
      css={[
        darkThemeStyle,
        css({
          borderRadius: '8px',
          border: `${tokens.borderWidth.medium} solid ${tokens.colors.navySubtleTextOnLight}`,
          width: '372px',
          maxWidth: `calc(100vw - ${tokens.spacing.large} * 2)`,
        }),
      ]}
    >
      <div
        css={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'nowrap',
          gap: tokens.spacing.xsmall,
          justifyContent: 'center',
          opacity: isStreakAnimationFinished ? 1 : 0.4,
          overflow: 'hidden',
          padding: tokens.spacing.small,
          textAlign: 'center',
          transition: `opacity ${ANIMATION_TIME}ms`,
          [mediaQuery.aboveSmall]: {
            padding: '12px',
          },
        }}
      >
        <FlashShimmer
          css={{
            width: sizes.flash,
            display: 'inline-block',
            flexShrink: 0,
          }}
        />

        <div
          css={{
            color: tokens.colors.greyLight,
            display: 'inline-block',
            flexShrink: 1,
            font: tokens.fontFamilies.sansSerif,
            fontSize: sizes.font,
            fontWeight: tokens.fontWeights.bold,
            lineHeight: tokens.lineHeights.default,
            perspective: '1000px',
            position: 'relative',
            transformStyle: 'preserve-3d',
          }}
        >
          <span
            css={{
              backfaceVisibility: 'hidden',
              display: 'inline-block',
              transitionProperty: 'transform, opacity',
              transitionDuration: `${ANIMATION_TIME}ms`,
              transitionTimingFunction:
                'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
              transform: isStreakAnimationFinished
                ? 'translate(0, 0)'
                : 'translate(0, -75%)',
              opacity: isStreakAnimationFinished ? 1 : 0,
            }}
          >
            {streakLengthInDays}
          </span>

          <span
            aria-hidden
            css={{
              backfaceVisibility: 'hidden',
              display: 'inline-block',
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
              transform: isStreakAnimationFinished
                ? 'translate(0, 75%)'
                : 'translate(0, 0)',
              transitionProperty: 'transform, opacity',
              transitionDuration: `${ANIMATION_TIME}ms`,
              transitionTimingFunction:
                'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
              opacity: isStreakAnimationFinished ? 0 : 1,
            }}
          >
            {streakLengthInDays - 1}
          </span>
        </div>
      </div>

      <div
        css={{
          background: tokens.colors.navyMedium,
          borderRadius: '0 0 8px 8px',
          display: 'flex',
          flexWrap: 'nowrap',
          gap: tokens.spacing.small,
          justifyContent: 'center',
          padding: tokens.spacing.medium,
          paddingTop: tokens.spacing.medium,
          [mediaQuery.aboveSmall]: {
            gap: tokens.spacing.medium,
            padding: tokens.spacing.large,
            paddingTop: tokens.spacing.medium,
          },
        }}
      >
        {days.map((day, index) => (
          <StreakDay
            day={day}
            key={index}
            status={streakWeekView[index].status}
            today={todayIndex === index}
          />
        ))}
      </div>
    </div>
  );
};
