export const getSocialLinks = (streakDays: number) => {
  const socialsTitle = `I just reached ${streakDays} days of learning on DataCamp 💪`;
  const encodedTitle = encodeURIComponent(socialsTitle);
  const htmlUrl = `https://assets.datacamp.com/streaks/share-streak-${streakDays}.html`;

  const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&source=DataCamp&summary=${encodedTitle}&title=${encodedTitle}&url=${htmlUrl}`;
  const facebookUrl = `https://www.facebook.com/dialog/feed?app_id=408074879325153&display=page&link=${htmlUrl}`;
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${htmlUrl}&via=DataCamp`;

  return { linkedInUrl, facebookUrl, twitterUrl };
};
