/** @jsx jsx */
import { Heading } from '@datacamp/waffles/heading';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { css, jsx } from '@emotion/react';
import cn from 'classnames';
import type { ReactElement } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import config from '../../config';
import { useDispatch, useSelector } from '../../interfaces/State';
import { streamCodeExplanationAction } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import {
  selectCodeExplanation,
  selectCodeExplanationTrigger,
} from '../../redux/selectors';
import DCSpinner from '../DCSpinner';
import hcCollapsePanel from '../HighOrderComponents/hcCollapsePanel';
import MarkdownRendererLoader from '../MarkdownRenderer/MarkdownRendererLoader';
import type { UiTheme } from '../ThemeToggler';

import Rating from './Rating';

type CodeExplanationProps = {
  isClosed: boolean;
  setClosed: (isClosed: boolean) => void;
  uiTheme: UiTheme;
};

const CodeExplanation: React.FC<CodeExplanationProps> = ({
  isClosed,
  uiTheme,
}): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLightTheme = uiTheme === 'LIGHT';
  const { codeExplanation } = useSelector(selectCodeExplanation);
  const codeExplanationInput = useSelector(
    selectors.selectCodeExplanationInput,
  );
  const codeExplanationTrigger = useSelector(selectCodeExplanationTrigger);
  const modelTag = config.isProductionDomain()
    ? 'learn-explain-solution-prod'
    : 'learn-explain-solution-staging';

  useEffect(() => {
    if (codeExplanation.type === 'initial') {
      dispatch(
        streamCodeExplanationAction({
          input: codeExplanationInput,
          trigger: codeExplanationTrigger,
          modelTag,
        }),
      );
    }
  }, [
    codeExplanation.type,
    codeExplanationInput,
    codeExplanationTrigger,
    dispatch,
    modelTag,
  ]);

  if (codeExplanation.type === 'loading') {
    return (
      <div
        className={cn('code-explanation-wrapper', { closed: isClosed })}
        css={css`
          height: 100%;
          background-color: ${isLightTheme
            ? tokens.colors.white
            : tokens.colors.navy};
        `}
      >
        <DCSpinner uiTheme={uiTheme} />
      </div>
    );
  }

  if (codeExplanation.type === 'error') {
    return (
      <div
        className={cn('code-explanation-wrapper', { closed: isClosed })}
        css={css`
          display: flex;
          flex-direction: column;
          height: 100%;
          background-color: ${isLightTheme
            ? tokens.colors.white
            : tokens.colors.navy};
          align-items: center;
          justify-content: center;
        `}
      >
        <Heading as="h3" inverted={uiTheme !== 'LIGHT'}>
          {t('CodeExplanation.errorHeading')}
        </Heading>
        <Paragraph inverted={uiTheme !== 'LIGHT'}>
          {t('CodeExplanation.errorText')}
        </Paragraph>
      </div>
    );
  }

  return (
    <div
      className={cn('code-explanation-wrapper', { closed: isClosed })}
      data-cy="code-explanation-container"
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: ${isLightTheme
          ? tokens.colors.white
          : tokens.colors.navy};
        padding: ${tokens.spacing.medium};
      `}
    >
      {(codeExplanation.type === 'streaming' ||
        codeExplanation.type === 'success') &&
        codeExplanation.prediction && (
          <>
            <div
              css={css`
                height: 100%;
                overflow-y: auto;
              `}
            >
              <MarkdownRendererLoader
                id="code-explanation-markdown"
                markdown={codeExplanation.prediction.completion}
                theme={uiTheme}
              />
            </div>
            {codeExplanation.type === 'success' && (
              <div
                css={css({
                  paddingTop: tokens.spacing.medium,
                  borderTop: 'solid',
                  borderTopWidth: tokens.borderWidth.thin,
                  borderTopColor: isLightTheme
                    ? tokens.colors.transparentGreySoft
                    : tokens.colors.transparentWhiteSoft,
                  insetBlockEnd: tokens.spacing.xsmall,
                })}
              >
                <Rating
                  inverted={uiTheme !== 'LIGHT'}
                  predictionId={codeExplanation.prediction.id}
                />
              </div>
            )}
          </>
        )}
    </div>
  );
};

export default hcCollapsePanel({ fromTopToBottom: true, closedBarHeight: 33 })(
  CodeExplanation,
);
