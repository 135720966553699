/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Heading } from '@datacamp/waffles/heading';
import { mediaQuery } from '@datacamp/waffles/helpers';
import { useMediaQuery } from '@datacamp/waffles/hooks';
import { ArrowRight } from '@datacamp/waffles/icon';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from '../../interfaces/State';
import { selectStreakDays, selectStreakWeekView } from '../../redux/selectors';

import DailyStreakScreenWrapper from './DailyStreakScreenWrapper';
import { DailyStreakShareButton } from './DailyStreakShareButton';
import { isStreakMilestone } from './isStreakMilestone';
import { StreakWidget } from './StreakWidget';

function useCopy(
  streakDays?: number,
): { headerText: string; hintText: string } {
  const { t } = useTranslation();

  if (!streakDays) {
    return {
      headerText: '',
      hintText: '',
    };
  }

  if (streakDays === 1) {
    return {
      headerText: `${t('DailyStreakModal.headerStarted')} ⚡️`,
      hintText: t('DailyStreakModal.hintFirstDay'),
    };
  }

  if (streakDays < 7) {
    return {
      headerText: `${t('DailyStreakModal.headerFirstWeek')} 🚀`,
      hintText: t('DailyStreakModal.hintFirstWeek', { count: 7 - streakDays }),
    };
  }

  return {
    headerText: `${t('DailyStreakModal.headerExtended')} 🚀`,
    hintText: t('DailyStreakModal.hintLongTerm'),
  };
}

const DailyStreakScreen: React.FC<{ onExit: () => void }> = (props) => {
  const { onExit } = props;

  const { t } = useTranslation();
  const autoFocusRef = useRef(null);
  const { isAboveSmall } = useMediaQuery();

  const streakDays = useSelector(selectStreakDays);
  const streakWeekView = useSelector(selectStreakWeekView);

  const { headerText, hintText } = useCopy(streakDays);

  const isMilestone = isStreakMilestone(streakDays ?? 0);

  return (
    <DailyStreakScreenWrapper initialFocus={autoFocusRef}>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          [mediaQuery.aboveSmall]: { flexGrow: 0 },
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <StreakWidget
          streakLengthInDays={streakDays ?? 0}
          streakWeekView={streakWeekView}
        />
        <Heading
          data-testid="streak-day-reached-header"
          css={{
            marginTop: tokens.spacing.xlarge,
            fontSize: '32px',
          }}
        >
          {headerText}
        </Heading>
        <Paragraph
          css={{
            marginBottom: tokens.spacing.xlarge,
          }}
        >
          {hintText}
        </Paragraph>
      </div>

      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          flexWrap: 'wrap',
          gap: tokens.spacing.medium,
          position: 'relative',
        }}
      >
        {isMilestone && <DailyStreakShareButton />}

        <Button
          ref={autoFocusRef}
          variant={isMilestone ? 'regularOutline' : 'cta'}
          iconRight={isAboveSmall ? <ArrowRight /> : undefined}
          fullWidth={!isAboveSmall}
          onClick={onExit}
        >
          {t('DailyStreakModal.cta')}
        </Button>
      </div>
    </DailyStreakScreenWrapper>
  );
};

export default DailyStreakScreen;
