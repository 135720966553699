/** @jsx jsx */
import { darkThemeStyle } from '@datacamp/waffles/theme';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import { FloatingFocusManager, useFloating } from '@floating-ui/react';
import type { MutableRefObject } from 'react';

const DailyStreakScreenWrapper: React.FC<{
  initialFocus?: MutableRefObject<HTMLElement | null>;
}> = (props) => {
  const { children, initialFocus } = props;

  const { context } = useFloating();

  return (
    <FloatingFocusManager
      context={context}
      initialFocus={initialFocus}
      modal
      closeOnFocusOut={false}
    >
      <div
        css={[
          darkThemeStyle,
          {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: tokens.colors.navy,
            zIndex: tokens.zIndex.modal,
            display: 'flex',
            flexDirection: 'column',
            padding: tokens.spacing.xlarge,
            justifyContent: 'center',
            alignItems: 'stretch',
          },
        ]}
      >
        {children}
      </div>
    </FloatingFocusManager>
  );
};

export default DailyStreakScreenWrapper;
