import { Button } from '@datacamp/waffles/button';
import { useMediaQuery } from '@datacamp/waffles/hooks';
import { tokens } from '@datacamp/waffles/tokens';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ENABLED_EXERCISE_TYPES = [
  'NormalExercise',
  'NormalCodingExercise',
  'ShinyExercise',
  'MultipleChoiceExercise',
  'BokehServerExercise',
  'SingleProcessExercise',
  'ExamExercise',
  'MarkdownExercise',
  'TabExercise',
  'BulletExercise',
  'TabConsoleExercise',
  'BulletConsoleExercise',
  'RCppExercise',
  'ConsoleExercise',
];

type Props = {
  exerciseType: string;
  mobileView: 'CONTEXT' | 'EXERCISE';
  setMobileView: (selectedView: 'CONTEXT' | 'EXERCISE') => void;
};

const EditorToggle: React.FC<Props> = ({
  exerciseType,
  mobileView,
  setMobileView,
}) => {
  const { t } = useTranslation();
  const { isAboveSmall } = useMediaQuery();

  // This is to prevent the editor toggle from being shown on first render as it flashes on desktop before redux has loaded
  const [isVisible, setIsVisible] = React.useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);

  if (
    isAboveSmall ||
    !isVisible ||
    !ENABLED_EXERCISE_TYPES.includes(exerciseType)
  ) {
    return null;
  }

  const setActiveTab = (tab: 'CONTEXT' | 'EXERCISE') => {
    if (tab === mobileView) {
      return;
    }

    setMobileView(tab);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
      data-testid="editor-toggle"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: tokens.spacing.xsmall,
          marginBottom: '20px',
          backgroundColor: tokens.colors.greyMedium,
          borderRadius: '6px',
          padding: tokens.spacing.xsmall,
        }}
      >
        <Button
          style={{
            backgroundColor:
              mobileView === 'CONTEXT'
                ? tokens.colors.navyDark
                : tokens.colors.greyMedium,
            color:
              mobileView === 'CONTEXT'
                ? tokens.colors.white
                : tokens.colors.navyDark,
            border: 'none',
          }}
          onClick={() => setActiveTab('CONTEXT')}
        >
          {t('EditorToggle.context')}
        </Button>
        <Button
          style={{
            backgroundColor:
              mobileView === 'EXERCISE'
                ? tokens.colors.navyDark
                : tokens.colors.greyMedium,
            color:
              mobileView === 'EXERCISE'
                ? tokens.colors.white
                : tokens.colors.navyDark,
            border: 'none',
          }}
          onClick={() => setActiveTab('EXERCISE')}
        >
          {t('EditorToggle.exercise')}
        </Button>
      </div>
    </div>
  );
};

export default EditorToggle;
