/** @jsx jsx */
import {
  OutputConsole as SharedOutputConsole,
  useAutoScrollConsoleToBottom,
} from '@datacamp/code-editor';
import { jsx } from '@emotion/react';
import raven from 'raven-js';
import { useCallback, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from '../../interfaces/State';
import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import Reconnecter from '../Reconnecter';

type Props = {
  uiTheme: 'DARK' | 'LIGHT';
};

export const OutputConsole: React.FC<Props> = ({ uiTheme }) => {
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const isSessionBroken = useSelector(selectors.isSessionBroken);
  const multiplexerOutputs = useSelector(selectors.selectOutput);
  const exerciseId = useSelector(selectors.selectExercise).get('id');
  const subExerciseId = useSelector(selectors.selectCurrentSubExercise).get(
    'id',
  );
  const currentExerciseId = subExerciseId != null ? subExerciseId : exerciseId;

  const clearConsoleOutput = useCallback(() => {
    dispatch(actions.clearConsoleOutput());
  }, [dispatch]);

  useEffect(() => {
    clearConsoleOutput();
  }, [clearConsoleOutput, currentExerciseId]);

  useEffect(() => {
    if (isSessionBroken) {
      dispatch(actions.clearConsoleOutput());
    }
  }, [dispatch, isSessionBroken]);

  useAutoScrollConsoleToBottom({
    divElementRef: containerRef,
    scrollWhenAnyValueChanges: [multiplexerOutputs],
  });

  return (
    <SharedOutputConsole
      clearConsoleOutput={clearConsoleOutput}
      exerciseId={exerciseId}
      isSessionBroken={isSessionBroken}
      multiplexerOutputs={multiplexerOutputs}
      uiTheme={uiTheme}
      onErrorReported={raven.captureException}
      reconnector={<Reconnecter />}
      subExerciseId={subExerciseId}
    />
  );
};
