import React, { useCallback } from 'react';

import config from '../../config';
import { createContentAuthorizationClient } from '../../helpers/ContentAuthorizationClient/ContentAuthorizationClient';
import type { State } from '../../interfaces/State';
import { useDispatch, useSelector } from '../../interfaces/State';
import {
  epicClearBackendClientSettings,
  fetchContentAuthorization,
  showModal,
  showPaywallModal,
  stopBackendSession,
} from '../../redux/actions';
import {
  selectChapterId,
  selectContentAuthorization,
  selectIsUserLoggedIn,
} from '../../redux/selectors';

import useContentAuthorizationService from './useContentAuthorizationService';

const casClient = createContentAuthorizationClient({
  baseUrl: config.urls.cas,
  serviceOrigin: config.appName,
});

const ContentAuthorizationService: React.FC = () => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  const chapterId = useSelector(selectChapterId);
  const casResult = useSelector((state: State) =>
    selectContentAuthorization(state, chapterId),
  );

  useContentAuthorizationService({
    isUserLoggedIn,
    fetchContentAuthorization: useCallback(
      (forChapterId: number) => {
        dispatch(fetchContentAuthorization(forChapterId, casClient));
      },
      [dispatch],
    ),
    showArchivedResourceModal: useCallback(() => {
      // @ts-expect-error Action is not yet typed
      dispatch(stopBackendSession());
      dispatch(epicClearBackendClientSettings());
      dispatch(showModal({ modal: { code: 'archived_resource' } }));
    }, [dispatch]),
    showPaywallModal: useCallback(() => {
      // @ts-expect-error Action is not yet typed
      dispatch(stopBackendSession());
      dispatch(epicClearBackendClientSettings());
      // @ts-expect-error Action is not yet typed
      dispatch(showPaywallModal());
    }, [dispatch]),
    casResult,
    chapterId,
  });

  return null;
};

export default React.memo(ContentAuthorizationService);
