import qs from 'qs';
import { useCallback } from 'react';

import config from '../../../config';
import { useSelector } from '../../../interfaces/State';
import unreachable from '../../../interfaces/unreachable';
import type {
  WorkspaceSourceCreationInfo,
  WorkspaceTemplate,
} from '../../../redux/reducers/notes';
import { selectCourseRef, selectNotes } from '../../../redux/selectors';

const getQueryParamsForWorkspaceTemplate = ({
  courseSlug,
  workspaceTemplate,
}: {
  courseSlug: string;
  workspaceTemplate: WorkspaceTemplate;
}): WorkspaceSourceCreationInfo & { utm_term: string } => {
  switch (workspaceTemplate._tag) {
    case 'course':
      return {
        _tag: 'course',
        utm_term: `course_source_${workspaceTemplate.courseId}_${courseSlug}`,
        courseId: workspaceTemplate.courseId,
      };
    case 'template':
      return {
        _tag: 'template',
        utm_term: `${workspaceTemplate.key}_${courseSlug}`,
        templateKey: workspaceTemplate.key,
      };
    default:
      return unreachable(workspaceTemplate);
  }
};

export const useOpenWorkspace = () => {
  const courseSlug = useSelector(selectCourseRef);
  const { workspaceTemplate } = useSelector(selectNotes);

  const openWorkspace = useCallback(() => {
    if (workspaceTemplate == null) {
      return;
    }

    const queryParams = {
      utm_source: 'learn-hub',
      utm_medium: 'in_product',
      utm_content: 'on_campus_exercises',
      openIfExistsKey: courseSlug,
      openIfExists: true,
      editorVersion: 'dcStudioEditor',
      title: workspaceTemplate.title,
      ...getQueryParamsForWorkspaceTemplate({ workspaceTemplate, courseSlug }),
    };
    const link = `${config.urls.datalab}/new?${qs.stringify(queryParams)}`;
    window.open(link, '_blank');
  }, [courseSlug, workspaceTemplate]);
  return { openWorkspace };
};
