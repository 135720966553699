/** @jsx jsx */
// eslint-disable-next-line filenames/match-exported
import type {
  FeedbackRatingProps,
  FeedbackRatingStatus,
} from '@datacamp/le-shared-components';
import { Feedbacks as SharedFeedback } from '@datacamp/le-shared-components';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { autoScrollTo } from '../../../../helpers/domManipulation';
import { isTeachPreview } from '../../../../helpers/isTeachPreview';
import { scrubAPITokens } from '../../../../helpers/scrubAPITokens';
import type { State } from '../../../../interfaces/State';
import { useSelector } from '../../../../interfaces/State';
import * as actions from '../../../../redux/actions';
import * as selectors from '../../../../redux/selectors';
import type { MarkdownRendererProps } from '../../../MarkdownRenderer/MarkdownRenderer';
import MarkdownRendererLoader from '../../../MarkdownRenderer/MarkdownRendererLoader';

import type { FeedbackMessageItem } from './helpers';
import { getAiIncorrectSubmissionMarkdownBody, getAiProps } from './helpers';

type StateProps = {
  exerciseId: number;
  feedbackMessages?: FeedbackMessageItem[];
  feedbackRatingStatus: FeedbackRatingStatus;
  hintAndSolution: {
    isSolution: boolean;
    isSolutionShown: boolean;
    isVisible: boolean;
    xp: number;
  };
  subExerciseId?: number;
};
type OwnProps = {
  language: string;
};
type DispatchProps = {
  onClickHintOrSolution: (
    language: string,
    isHintShown: boolean,
    exerciseId: number,
    xp: number,
  ) => void;
  onClickTab: (...args: any[]) => any;
  onFeedbackRated: (...args: any[]) => any;
  setHintShown: (isHintShown: boolean) => void;
  setUserUsedAiErrorExplanation: (value: boolean) => void;
  setUserUsedAiIncorrectSubmissions: (value: boolean) => void;
};
type Props = DispatchProps &
  OwnProps &
  StateProps & {
    history: any;
  };

const Feedback: React.FC<Props> = ({
  exerciseId,
  feedbackMessages = [],
  feedbackRatingStatus,
  hintAndSolution,
  history,
  language,
  onClickHintOrSolution,
  onClickTab,
  onFeedbackRated,
  setHintShown,
  setUserUsedAiErrorExplanation,
  setUserUsedAiIncorrectSubmissions,
  subExerciseId,
}) => {
  const feedbackRef = useRef();
  const previousFeedbackMessagesRef = useRef(feedbackMessages);
  const { t } = useTranslation();

  useEffect(() => {
    if (feedbackMessages.length > previousFeedbackMessagesRef.current.length) {
      autoScrollTo(feedbackRef.current);
    }
  }, [feedbackMessages]);

  const courseId = useSelector(selectors.selectCourse).get('id');
  const isPreview = isTeachPreview(history.location.pathname);
  const solution = useSelector(selectors.selectSolution);
  const courseTitle = useSelector(selectors.selectCourseTitle);
  const pec = useSelector(selectors.selectPEC);
  const userLanguage = useSelector(selectors.selectUserLanguage);
  const instructions = useSelector(selectors.selectInstructions);
  const isUserEnabledForAiErrorExplanation = useSelector(
    selectors.selectUserIsEnabledForAiErrorExplanation,
  );
  const isUserEnabledForAiIncorrectSubmissions = useSelector(
    selectors.selectUserIsEnabledForAiIncorrectSubmissions,
  );

  const getRatingForFeedback = useCallback(
    (feedbackMessage: FeedbackMessageItem): FeedbackRatingProps => ({
      isVisible: true,
      onRate: onFeedbackRated,
      ratingStatus: feedbackMessage.ratedFeedback
        ? 'SAVED'
        : feedbackRatingStatus,
      question:
        feedbackMessage.messageType === 'HINT'
          ? t('Feedback.hintRatingQuestion')
          : t('Feedback.feedbackRatingQuestion'),
    }),
    [feedbackRatingStatus, onFeedbackRated, t],
  );

  const transformedFeedbackMessages = feedbackMessages
    .filter((feedbackMessage) =>
      isUserEnabledForAiErrorExplanation
        ? true
        : feedbackMessage.messageType !== 'ASSISTANT',
    )
    .map((feedbackMessage) => {
      const isAiErrorExplanationFeedback =
        feedbackMessage.messageType === 'ASSISTANT';
      const isIncorrectSubmissionFeedback =
        feedbackMessage.messageType === 'INCORRECT';
      const body = isIncorrectSubmissionFeedback
        ? getAiIncorrectSubmissionMarkdownBody({
            body: feedbackMessage.body || '',
            isUserEnabledForAiIncorrectSubmissions,
          })
        : feedbackMessage.body;
      const rating = !isAiErrorExplanationFeedback
        ? getRatingForFeedback(feedbackMessage)
        : undefined;
      const scrubbedCode =
        feedbackMessage.lastRunCode != null || isPreview
          ? scrubAPITokens({
              courseId,
              submission: feedbackMessage.lastRunCode,
            })
          : feedbackMessage.lastRunCode;

      const aiAssistantProps =
        isAiErrorExplanationFeedback || isIncorrectSubmissionFeedback
          ? getAiProps({
              feedbackMessage,
              code: scrubbedCode,
              language,
              userLanguage,
              exerciseId,
              solution,
              courseId,
              courseTitle,
              pec,
              instructions,
              setHintShown,
              setUserUsedAiErrorExplanation,
              setUserUsedAiIncorrectSubmissions,
            })
          : undefined;
      return {
        ...feedbackMessage,
        body,
        rating,
        aiAssistantProps,
      };
    });

  // Set the last feedback message as active if we don't have any active feedback messages
  // This may happen if the last feedback message was filtered out above
  const hasActiveFeedbackMessage = transformedFeedbackMessages.some(
    (feedbackMessage) => feedbackMessage.isActive,
  );
  if (!hasActiveFeedbackMessage && transformedFeedbackMessages.length > 0) {
    transformedFeedbackMessages[
      transformedFeedbackMessages.length - 1
    ].isActive = true;
  }

  const Renderer: React.FC<MarkdownRendererProps> = useMemo(() => {
    return (p) => (
      <MarkdownRendererLoader id={`feedback-markdown-${exerciseId}`} {...p} />
    );
  }, [exerciseId]);

  return (
    <div
      css={{ margin: `${tokens.spacing.medium} -${tokens.spacing.medium} 0` }}
    >
      <SharedFeedback
        innerRef={feedbackRef}
        feedbackMessages={transformedFeedbackMessages}
        hintAndSolution={{
          ...hintAndSolution,
          onClick: () =>
            onClickHintOrSolution(
              language,
              hintAndSolution.isSolution,
              subExerciseId != null ? subExerciseId : exerciseId,
              hintAndSolution.xp,
            ),
        }}
        onClickTab={onClickTab}
        isUserEnabledForAISubmissionHint={
          isUserEnabledForAiIncorrectSubmissions
        }
        Renderer={Renderer}
      />
    </div>
  );
};

const mapStateToProps = (state: State): StateProps => ({
  feedbackMessages: selectors.selectFeedbackMessages(state).toJS(),
  feedbackRatingStatus: selectors.selectFeedbackRatingStatus(state),
  hintAndSolution: selectors.selectHintOrSolution(state),
  exerciseId: selectors.selectExercise(state).get('id'),
  subExerciseId: selectors.selectCurrentSubExercise(state).get('id'),
});

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  onClickTab: (tabIndex: number) =>
    dispatch(actions.updateFeedbackTab({ tabIndex })),

  onClickHintOrSolution: (
    language: string,
    isHintShown: boolean,
    exerciseId: number,
    xp: number,
  ) =>
    dispatch(
      isHintShown
        ? actions.showSolution(language, exerciseId, xp)
        : actions.showHint(exerciseId, xp),
    ),

  onFeedbackRated: ({ isUseful, message }: any) => {
    return dispatch(
      actions.epicSubmitFeedback({
        rating: isUseful ? 5 : 1,
        userFeedback: message,
      }),
    );
  },
  setHintShown: (isHintShown: boolean) =>
    dispatch(actions.setHintShown(isHintShown)),

  setUserUsedAiErrorExplanation: (value: boolean) =>
    dispatch(actions.setUserUsedAiErrorExplanation(value)),

  setUserUsedAiIncorrectSubmissions: (value: boolean) =>
    dispatch(actions.setUserUsedAiIncorrectSubmissions(value)),
});

export default withRouter(
  // @ts-expect-error leftover from when codebase was in javascript, fix later
  connect(mapStateToProps, mapDispatchToProps)(Feedback),
);
