/** @jsx jsx */
import { Rating } from '@datacamp/le-shared-components';
import { Dialog } from '@datacamp/waffles/dialog';
import { FormField } from '@datacamp/waffles/form-field';
import { Heading } from '@datacamp/waffles/heading';
import { Checkmark } from '@datacamp/waffles/icon';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { TextArea } from '@datacamp/waffles/text-area';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  message?: string;
  onRate: (...args: any[]) => any;
  onSkip?: (...args: any[]) => any;
  ratingThreshold?: number;
};

const ChapterRatingModal: React.FC<Props> = ({
  message = '',
  onRate,
  onSkip,
  ratingThreshold = 2,
}) => {
  const { t } = useTranslation();
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [rating, setRating] = useState<number | null>(null);

  const shouldShowFeedback = rating && rating <= ratingThreshold;

  const submit = useCallback(() => {
    if (onRate !== null) {
      onRate(rating, feedbackMessage);
    }
  }, [onRate, rating, feedbackMessage]);

  const handleRate = (newRating: any) => {
    setRating(newRating);
  };
  useEffect(() => {
    if (rating !== null && rating > ratingThreshold) {
      submit();
    }
  }, [rating, ratingThreshold, submit]);

  const handleSkip = () => {
    if (shouldShowFeedback) {
      setFeedbackMessage('');
      submit();
    } else {
      // @ts-expect-error ts-migrate(2349) FIXME: Type 'never' has no call signatures.
      onSkip();
    }
  };

  return (
    <Dialog isOpen onClose={handleSkip} alignCenter>
      <Dialog.Header mode="decorative" variant="success">
        {t('ChapterRatingModal.header')}
      </Dialog.Header>
      <Dialog.Body>
        {message && (
          <Paragraph css={{ marginBottom: tokens.spacing.large }}>
            {message}
          </Paragraph>
        )}
        {shouldShowFeedback ? (
          <FormField label={t('ChapterRatingModal.feedbackLabel')} required>
            <TextArea
              value={feedbackMessage}
              onChange={(event) => {
                setFeedbackMessage(event.target.value);
              }}
              rows={4}
            />
          </FormField>
        ) : (
          <div css={{ paddingBottom: tokens.spacing.medium }}>
            <Heading size="large">
              {t('ChapterRatingModal.ratingHeading')}
            </Heading>
            <Rating onRate={handleRate} size={24} wrapped />
          </div>
        )}
      </Dialog.Body>
      {shouldShowFeedback && (
        <Dialog.Footer>
          <Dialog.Button
            iconLeft={<Checkmark />}
            variant="primary"
            onClick={submit}
          >
            {t('ChapterRatingModal.feedbackButton')}
          </Dialog.Button>
        </Dialog.Footer>
      )}
    </Dialog>
  );
};

export default ChapterRatingModal;
