/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { useMediaQuery } from '@datacamp/waffles/hooks';
import {
  FacebookBrand,
  LinkedInBrand,
  Share,
  TwitterBrand,
} from '@datacamp/waffles/icon';
import { Link } from '@datacamp/waffles/link';
import { Popover } from '@datacamp/waffles/popover';
import { lightThemeStyle } from '@datacamp/waffles/theme';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from '../../interfaces/State';
import { selectStreakDays } from '../../redux/selectors';

import { getSocialLinks } from './getSocialLinks';

const ShareButton: React.FC<{
  ariaLabel: any;
  color: string;
  href: string;
  trackId: string;
}> = (props) => (
  <Button
    aria-label={props.ariaLabel}
    as={Link}
    target="_blank"
    css={{
      ':hover:not(:disabled)': {
        background: props.color,
      },
      background: props.color,
      color: tokens.colors.white,
    }}
    data-trackid={props.trackId}
    href={props.href}
  >
    {props.children}
  </Button>
);

export const DailyStreakShareButton: React.FC = () => {
  const { t } = useTranslation();
  const { isAboveSmall } = useMediaQuery();
  const streakDays = useSelector(selectStreakDays);
  const [open, setOpen] = useState(false);

  const { facebookUrl, linkedInUrl, twitterUrl } = getSocialLinks(
    streakDays ?? 0,
  );

  const onToggle = useCallback(() => setOpen((val) => !val), []);

  return (
    <Popover
      css={[lightThemeStyle, { minHeight: 'unset' }]}
      closeOnOutsidePress
      isOpen={open}
      onClose={() => setOpen(false)}
      placement="topLeft"
      target={
        <Button
          variant="cta"
          iconLeft={<Share />}
          fullWidth={!isAboveSmall}
          onClick={onToggle}
          data-trackid={'campus-learn-streak-share-achievement'}
        >
          {t('DailyStreakModal.share')}
        </Button>
      }
    >
      <Popover.Header>
        {t('DailyStreakModal.sharePopover.header')}
      </Popover.Header>

      <div
        css={{
          a: {
            flexGrow: 1,
            fontSmoothing: 'antialiased',
            marginBottom: 0,
            WebkitFontSmoothing: 'antialiased',
            border: 'none',
          },
          display: 'flex',
          gap: tokens.spacing.small,
          justifyContent: 'center',
          padding: `0 ${tokens.spacing.medium} ${tokens.spacing.medium}`,
          flexWrap: 'wrap',
        }}
      >
        <ShareButton
          ariaLabel={t('ShareAccomplishment.shareOnLinkedIn')}
          color="#0e76a8"
          href={linkedInUrl}
          trackId="campus-learn-streak-share-achievement-linkedin"
        >
          <LinkedInBrand color={tokens.colors.white} />
        </ShareButton>

        <ShareButton
          ariaLabel={t('ShareAccomplishment.shareOnFacebook')}
          color={tokens.colors.brandFacebook}
          href={facebookUrl}
          trackId="campus-learn-streak-share-achievement-facebook"
        >
          <FacebookBrand color={tokens.colors.white} />
        </ShareButton>

        <ShareButton
          ariaLabel={t('ShareAccomplishment.shareOnTwitter')}
          color={tokens.colors.brandTwitter}
          href={twitterUrl}
          trackId="campus-learn-streak-share-achievement-twitter"
        >
          <TwitterBrand color={tokens.colors.white} />
        </ShareButton>
      </div>
    </Popover>
  );
};
