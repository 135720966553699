/** @jsx jsx */
import { Dialog } from '@datacamp/waffles/dialog';
import { jsx } from '@emotion/react';

import CourseOutline from '.';

const CourseOutlineModal = ({
  onClose,
  showOutline,
}: {
  onClose: () => void;
  showOutline: boolean;
}): JSX.Element => {
  const closeModal = (): void => {
    onClose();
  };

  if (!showOutline) {
    return (
      <div
        aria-hidden="true"
        css={{
          height: 0,
          overflow: 'hidden',
          position: 'absolute',
          top: -9000,
          left: -9000,
        }}
      >
        <CourseOutline headless onExit={closeModal} />
      </div>
    );
  }

  return (
    <Dialog
      isOpen={showOutline}
      onClose={closeModal}
      css={{ width: 900, maxWidth: '90dvw' }}
    >
      <CourseOutline onExit={closeModal} />
    </Dialog>
  );
};

export default CourseOutlineModal;
