import { tokens } from '@datacamp/waffles/tokens';
import React from 'react';

import SnowflakeIcon from '../../assets/images/Snowflake.svg';

export const FreezeIcon = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: tokens.colors.blueDark,
      borderRadius: tokens.borderRadius.circle,
      height: 75,
      justifyContent: 'center',
      margin: 'auto',
      width: 75,
      boxShadow: '10.77px 5.38px 26.92px 0px #00000029',
    }}
  >
    <img src={SnowflakeIcon} height={45} width={45} alt="Freeze Icon" />
  </div>
);

export const FreezeIconRight = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: tokens.colors.blueDark,
      borderRadius: tokens.borderRadius.circle,
      height: 75,
      justifyContent: 'center',
      width: 75,
      margin: 'auto',
      marginLeft: '-12px',
      boxShadow: '-5.38px 5.38px 26.92px 0px #00000029',
    }}
  >
    <img src={SnowflakeIcon} height={45} width={45} alt="Freeze Icon" />
  </div>
);

export const DoubleFreezeIcon = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: tokens.spacing.large,
    }}
  >
    <FreezeIcon />
    <FreezeIconRight />
  </div>
);
